<template>
  <div style="max-height: min-content; font-size: 10px" class="q-ma-sm">
    <div class="text-left q-ml-sm" id="recibo">
      <q-img
        src="../../../../public/icons/icon-512x512.png"
        style="height: 100px; max-width: 100px"
      />
      <q-table :title="report.title" :rows="report.data" flat :pagination="pagination" :hide-bottom="true" wrap-cells/>
    </div>
  </div>
</template>
<script>
export default {
  name: "PrintReport",
  data() {
    return {
      report: {
        title:this.$route.query.title,
        data: JSON.parse(this.$route.query.data),
      },
      pagination: {
        rowsPerPage: 0,
      },
    };
  },
  async created() {
    //this.report.data = JSON.parse(this.$route.query.data);
  },
  async mounted() {
    await this.checkPafeReady();
  },
  methods: {
    checkPafeReady() {
      window.addEventListener("load", function () {
        window.print();
      });
    },
    async printPage() {
      //this.report.data = await JSON.parse(this.$route.query.data);
      window.print();
    },
  },
};
</script>
