<template>
  <div
    :style="`max-height: min-content; font-size: ${this.pageSettings.fontSize}`"
    class="q-ma-sm"
  >
    <div class="text-left" id="recibo">
      <q-avatar rounded size="70px">
        <img :src="loja?.LOGO ? loja.LOGO : defaultLogo" />
      </q-avatar>
      <br />
      <br />
      <strong>
        {{ loja?.NOME }}<br />
        {{ loja?.WHATSAPP }}<br />
        {{ loja?.CPF_CNPJ }}
      </strong>
      <br />
      <br />
      <strong>RECIBO DE MOVIMENTO DE CAIXA </strong>
      <br />
      DOCUMENTO NAO FISCAL! <br />
      <br />
      DATA MOVIMENTO: {{ dateFormat(recibo[0]?.DATA) }}
      <br />
      <br />
      <div v-if="loja?.TAMANHO_IMPRESSAO !== 'A4'">
        <strong>TOTALIZADORES</strong>
        <ul v-for="item in recibo" :key="item">
          <li>
            {{ item.TIPO_PAGAMENTO }}: R$
            {{ parseFloat(item.TOTAL, 0).toFixed(2) }}
          </li>
        </ul>
      </div>
      <q-table
        :rows="recibo"
        flat
        :columns="columns"
        hide-bottom
        :pagination="pagination"
        v-else
      />
      <br />
      <br />
      <strong
        >TOTAL MOVIMENTO: R${{
          parseFloat(recibo[0]?.TOTAL_DIA, 0).toFixed(2)
        }}</strong
      >
      <br />
      <div class="text-center">
        <p>{{ recibo.DATA }}{{ recibo.ID }}<br /></p>
        MINI PDV - CONTROLE DE VENDAS E ESTOQUE
      </div>
    </div>
  </div>
</template>
<script>
import formaters from "../../../helpers/formaters";
export default {
  name: "ReciboPage",
  data() {
    return {
      recibo: {},
      logoPath: "",
      loja: JSON.parse(localStorage.getItem("loja")),
      columns: [
        {
          name: "tipo",
          label: "Tipo Pagamento",
          field: "TIPO_PAGAMENTO",
          align: "left",
          sortable: true,
        },
        {
          name: "total",
          label: "Total R$",
          field: "TOTAL",
          align: "left",
          sortable: true,
        },
      ],
      pagination: {
        rowsPerPage: 0,
      },
      pageSettings: {
        fontSize: "14px",
      },
      tipo: "",
    };
  },
  async created() {
    this.recibo = await JSON.parse(this.$route.query.data);

    this.tipo = this.$route.query.tipo;
    this.setFontSize();
    this.printPage();
  },
  async mounted() {},
  computed: {
    defaultLogo() {
      return require("../../../../public/icons/icon-512x512.png");
    },
  },
  methods: {
    printPage() {
      window.print();
    },
    dateFormat(date) {
      return formaters.receiptDate(date);
    },
    setFontSize() {
      switch (this.loja.TAMANHO_IMPRESSAO) {
        case "58mm":
          this.pageSettings.fontSize = "9px";
          break;

        case "80mm":
          this.pageSettings.fontSize = "11px";
          break;

        default:
          this.pageSettings.fontSize = "14px";
          break;
      }
    },
  },
};
</script>
