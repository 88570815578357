<template>
  <div style="max-height: min-content; font-size: 12px;" class="q-ma-sm">
    <div class="text-left q-ml-sm" id="recibo">
      <q-img
        src="../../../../public/icons/icon-512x512.png"
        style="height: 100px; max-width: 100px"
        spinner-color=""
      />
      <p>
        MINI PDV <br />
        SISTEMA DE CONTROLE DE VENDAS<br />
        DOCUMENTO NAO FISCAL
      </p>
      <div class="text-h6">Comprovante de entrada</div>
      DATA ENTRADA: {{ dateFormat(recibo.DATA) }}
      <div v-if="recibo.ID_FORNECEDOR">FORNECEDOR: <strong>{{ recibo.NOME }}</strong></div>
      <p>================================================================================</p>
      QTD PROD. VL UNT TOTAL <br>
      ----------------------------------------------------------------------------------------------------------------------------------------------------------------
      <div v-for="produto in recibo.produtosEntrada" :key="produto">
        {{ produto.QUANTIDADE }}x {{ produto.NOME }} R${{ produto.CUSTO }} = R${{ produto.TOTAL }}
      </div>
      <p>================================================================================</p>
      <strong>TOTAL DA ENTRADA: R${{ recibo.TOTAL }}</strong>
      <p>Forma de pagamento: {{ recibo.TIPO_PAGAMENTO }}</p>
      {{ recibo.ID }}
    </div>
  </div>
</template>
<script>
import formaters from "../../../helpers/formaters";
export default {
  name: "ReciboPage",
  data() {
    return {
      recibo: {},
      logoPath: "",
    };
  },
  mounted() {
    this.recibo = JSON.parse(this.$route.query.data);
    this.printPage();
  },
  methods: {
    printPage() {
      window.print();
    },
    dateFormat(date) {
      return formaters.receiptDate(date);
    },
  },
};
</script>
